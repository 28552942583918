import React, { FC, useMemo } from 'react'
import { Box, ToastId } from '@chakra-ui/react'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TablistPageTopicGroupType, TablistPageType } from '../../../models/tablist_pages.types'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { SelectedTablistPagesType } from '../../../redux/selectedPagesSlice'
import { MoveTablistPageInfoType } from '../moveItemMenu/moveItemMenu.types'
import SavedTabsTopicGroupCard from './SavedTabsTopicGroupCard'
import { PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM } from '../PutasideTabList/PutasideTabView'

function groupPagesByGroupId(pages: TablistPageType[]): (TablistPageType | TablistPageType[])[] {
  const groupArrays: { [groupId: string]: TablistPageType[] } = {}

  const groupSizes: { [groupId: string]: number } = pages.reduce(
    (acc, page) => {
      const groupId = page.group_id
      if (groupId === undefined) {
        return acc
      }
      acc[groupId] = (acc[groupId] ?? 0) + 1
      return acc
    },
    {} as { [groupId: string]: number },
  )

  return pages.reduce(
    (acc, page) => {
      const groupId = page.group_id
      if (groupId === undefined || groupSizes[groupId] < 2) {
        acc.push(page)
      } else if (groupArrays[groupId]) {
        groupArrays[groupId].push(page)
      } else {
        const newGroupArray = [page]
        groupArrays[groupId] = newGroupArray
        acc.push(newGroupArray)
      }

      return acc
    },
    [] as (TablistPageType | TablistPageType[])[],
  )
}

interface Props {
  pages: TablistPageType[]
  selectedPages: SelectedTablistPagesType
  highlightedPageIds: string[]
  pendingFolderCreationPages: TablistPageType[]
  groups?: Record<string, TablistPageTopicGroupType>
  deleteTablistPage: (params: { page: TablistPageType; index: number }) => Promise<void>
  handleOpenMoveSavedItemModal: (params: { pageInfo: MoveTablistPageInfoType }) => void
  handleCreateProject: (title: string, tablistPages: TablistPageType[]) => Promise<void>
  handlePageSelection: (params: {
    clickedItemId: string
    isShiftKey: boolean
    isCtrlOrCmdKey: boolean
    isCurrentlySelected: boolean
    extraAnalyticsProps?: Record<string, unknown>
  }) => void
  handleRemovePageSelection: (params: { pageId: string }) => void
  handleProjectLimitError: () => void
  isProjectLimitReached: boolean
  shouldHideCardActionIcons: boolean
  isFolderCreationModeActive: boolean
  toastIdRef: React.MutableRefObject<ToastId | undefined>
}

const SavedTabsCard: FC<Props> = (props) => {
  const {
    pages,
    pendingFolderCreationPages,
    selectedPages,
    highlightedPageIds,
    groups,
    deleteTablistPage,
    handleOpenMoveSavedItemModal,
    handleCreateProject,
    handlePageSelection,
    handleRemovePageSelection,
    handleProjectLimitError,
    isProjectLimitReached,
    shouldHideCardActionIcons,
    isFolderCreationModeActive,
  } = props

  const groupedPages = useMemo((): (TablistPageType | TablistPageType[])[] => {
    return groupPagesByGroupId(pages)
  }, [pages])

  return (
    <Box>
      {groupedPages.map((item, idx) => {
        if (Array.isArray(item)) {
          const groupId = item[0].group_id
          if (groupId === undefined) {
            return <React.Fragment key={item[0].id} />
          }

          const group: TablistPageTopicGroupType = groups?.[groupId] ?? {
            title: 'Untitled Group',
            last_visited_time: 0,
          }

          return (
            <Box key={`group-${groupId}`}>
              <SavedTabsTopicGroupCard
                groupId={groupId}
                index={idx}
                pages={item}
                title={group.title}
                showAiSuggestion={!!group.ai_suggestion}
                aiSuggestionReason={group.ai_suggestion?.reason}
                pendingFolderCreationPages={pendingFolderCreationPages}
                lastVisitedTime={group.last_visited_time}
                numTotalResults={pages.length}
                highlightedPageIds={highlightedPageIds}
                selectedPages={selectedPages}
                onDeleteTablistPage={deleteTablistPage}
                onOpenPageMoveMenu={handleOpenMoveSavedItemModal}
                onPageSelected={handlePageSelection}
                removePageSelection={handleRemovePageSelection}
                isFolderCreationModeActive={isFolderCreationModeActive}
                handleCreateProject={handleCreateProject}
                handleProjectLimitError={handleProjectLimitError}
                isProjectLimitReached={isProjectLimitReached}
                shouldHideCardActionIcons={shouldHideCardActionIcons}
              />
            </Box>
          )
        } else {
          const page = item
          const prev = idx > 0 ? groupedPages[idx - 1] : false
          const prevTab = prev && !Array.isArray(prev) ? prev : false
          const next = idx < groupedPages.length - 1 ? groupedPages[idx + 1] : false
          const nextTab = next && !Array.isArray(next) ? next : false
          const isTabAboveSelected = prevTab && prevTab.id in selectedPages
          const isTabBelowSelected = nextTab && nextTab.id in selectedPages

          return (
            <Box key={`page-${page.id}`}>
              <PutasideTabController
                id={page.id}
                page={page}
                styleVariant={PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.SAVED_FOR_LATER}
                queryValue=""
                index={idx}
                numTotalResults={pages.length}
                areaName={TABLIST_AREA_NAME_ENUM.RecentlySaved}
                showTimeString={true}
                showActionIcons={true}
                showOpenTabIcon={page.is_open}
                onDeleteTablistPage={deleteTablistPage}
                shouldShowDeleteIcon={true}
                shouldShowFolderCreationAddIcon={isFolderCreationModeActive}
                isPendingInFolderCreation={pendingFolderCreationPages.some(
                  (p) => p.instance_id === page.instance_id,
                )}
                onOpenMoveMenu={handleOpenMoveSavedItemModal}
                onSelected={handlePageSelection}
                removeSelection={handleRemovePageSelection}
                isSelected={page.id in selectedPages}
                isTabAboveSelected={isTabAboveSelected}
                isTabBelowSelected={isTabBelowSelected}
              />
            </Box>
          )
        }
      })}
    </Box>
  )
}

export default SavedTabsCard
