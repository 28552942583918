import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, ToastId, useToast, UseToastOptions } from '@chakra-ui/react'
import { SmartSessionType } from '../../../models/smart_sessions.types'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import SmartSessionCardTitle from './SmartSessionCardTitle'
import { css, styled } from 'styled-components'
import { useUserContext } from '../../../contexts/UserContext'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { useDrag } from 'react-dnd'
import { DND_ITEM_ENUM, DnDItemSmartSessionPayload } from '../../../models/dnd.types'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { setIsSmartSessionDragging } from '../../../redux/dndSlice'
import { getEmptyImage } from 'react-dnd-html5-backend'
import {
  useDeleteSmartSessionMutation,
  useDeleteSmartSessionPageMutation,
} from '../../../redux/services/skeema/smart_sessions.endpoints'
import NotificationToast from '../../../components/NotificationToast'
import { SelectedTablistPagesType } from '../../../redux/selectedPagesSlice'
import {
  addPendingFolderCreationPages,
  selectPendingFolderCreationPages,
} from '../../../redux/projectsSlice'

const Container = styled(Box)<{
  $isDraggingThis: boolean
  $isHoverDisabled: boolean
}>`
  padding: 10px 4px 6px 4px;
  transition: all 0.2s ease-in-out;
  opacity: ${({ $isDraggingThis }) => ($isDraggingThis ? 0.5 : 1)};

  border: 1px solid #d5d5d5;
  border-radius: 8px;

  ${({ $isHoverDisabled }) =>
    $isHoverDisabled
      ? ''
      : css`
          &:hover {
            background-color: #fff;
            box-shadow:
              0px 0px 4px 0px rgba(0, 0, 0, 0.04),
              0px 4px 8px 0px rgba(0, 0, 0, 0.06);

            h2 {
              color: #000;
            }

            .hover-show-title-icons {
              opacity: 1;
            }
          }
        `}
`

const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

interface Props {
  session: SmartSessionType
  selectedPages: SelectedTablistPagesType
  handleCreateProject: (title: string, tablistPages: TablistPageType[]) => Promise<void>
  handleCardPageSelection: (params: {
    clickedSessionId: string
    clickedPageId: string
    isShiftKey: boolean
    isCtrlOrCmdKey: boolean
    isCurrentlySelected: boolean
    extraAnalyticsProps?: Record<string, unknown>
  }) => void
  handleRemoveCardPageSelection: (params: { pageId: string; sessionId: string }) => void
  handleProjectLimitError: () => void
  isProjectLimitReached: boolean
  isSSOnboardingActive: boolean
  shouldHideCardActionIcons: boolean
  isFolderCreationModeActive: boolean
  toastIdRef: React.MutableRefObject<ToastId | undefined>
}

const SmartSessionCard: FC<Props> = (props) => {
  const {
    session,
    selectedPages,
    handleCreateProject,
    handleCardPageSelection,
    handleRemoveCardPageSelection,
    handleProjectLimitError,
    isProjectLimitReached,
    isSSOnboardingActive,
    shouldHideCardActionIcons,
    isFolderCreationModeActive,
    toastIdRef,
  } = props
  const pendingFolderCreationPages = useReduxSelector(selectPendingFolderCreationPages)
  const { captureAnalytics } = useUserContext()
  const dispatch = useReduxDispatch()

  const toast = useToast(USE_TOAST_OPTIONS)

  const [deleteSmartSession] = useDeleteSmartSessionMutation()
  const [deleteSmartSessionPage] = useDeleteSmartSessionPageMutation()

  // Prevents creating duplicate projects during onboarding
  const [disableCreateProject, setDisableCreateProject] = useState<boolean>(false)
  useEffect(() => {
    if (!isSSOnboardingActive) {
      setDisableCreateProject(false)
    }
  }, [isSSOnboardingActive])

  const pages = session.pages

  const handleClickOpen = useCallback(
    async (newWindow: boolean) => {
      const urls = session.pages.map((page) => page.url)

      captureAnalytics(
        `smart_session_card:${
          newWindow ? 'open_pages_in_new_window_icon_click' : 'open_pages_icon_click'
        }`,
        {
          ...session,
          urls,
        },
      )

      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABLIST_PAGES, {
        pages: session.pages,
        newWindow,
      })
    },
    [captureAnalytics, session],
  )

  const handleClickOpenInNewWindowIcon = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      await handleClickOpen(true)
    },
    [handleClickOpen],
  )

  const handleClickOpenIcon = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      await handleClickOpen(false)
    },
    [handleClickOpen],
  )

  const handleClickFolderCreationAddAllButton = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      dispatch(addPendingFolderCreationPages({ pages: session.pages }))
      captureAnalytics('smart_session_card:folder_creation_add_all_button_click', {
        ...session,
      })
    },
    [captureAnalytics, dispatch, session],
  )

  const handleClickCreateProject = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      if (disableCreateProject) {
        return
      }
      if (isSSOnboardingActive) {
        //Prevent users creating multiple projects during onboarding
        setDisableCreateProject(true)
      }
      captureAnalytics('smart_session_card:create_project_icon_click', {
        ...session,
        pages,
        isProjectLimitReached,
      })

      if (isProjectLimitReached) {
        handleProjectLimitError()
      } else {
        await handleCreateProject(session.name, pages)
      }
    },
    [
      captureAnalytics,
      disableCreateProject,
      isSSOnboardingActive,
      handleCreateProject,
      handleProjectLimitError,
      isProjectLimitReached,
      pages,
      session,
    ],
  )

  const handleDeleteSmartSession = useCallback(async () => {
    await deleteSmartSession(session)
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
    toastIdRef.current = toast({
      duration: 3000,
      containerStyle: { minWidth: 'auto' },
      render: () => <NotificationToast message={`Session has been removed`} />,
    })

    captureAnalytics('smart_session_card:delete_session_icon_click', {
      ...session,
    })
  }, [captureAnalytics, deleteSmartSession, session, toast, toastIdRef])

  const handleDeleteSmartSessionPage = useCallback(
    async (params: { page: TablistPageType }) => {
      if (session.pages.length === 1) {
        await deleteSmartSession(session)
        if (toastIdRef.current) {
          toast.close(toastIdRef.current)
        }
        toastIdRef.current = toast({
          duration: 3000,
          containerStyle: { minWidth: 'auto' },
          render: () => <NotificationToast message={`Session has been removed`} />,
        })

        captureAnalytics('smart_session_card:delete_session_icon_click', {
          ...session,
          causedByDeletingLastPage: true,
        })

        captureAnalytics('smart_session_card:delete_session_page_icon_click', {
          ...session,
          page: params.page,
          isLastPage: true,
        })
      } else {
        await deleteSmartSessionPage({ session, page: params.page })

        captureAnalytics('smart_session_card:delete_session_page_icon_click', {
          ...session,
          page: params.page,
          isLastPage: false,
        })
      }
    },
    [captureAnalytics, deleteSmartSession, deleteSmartSessionPage, session, toast, toastIdRef],
  )

  const handlePageSelection = useCallback(
    (params: {
      clickedItemId: string
      isShiftKey: boolean
      isCtrlOrCmdKey: boolean
      isCurrentlySelected: boolean
    }) => {
      handleCardPageSelection({
        clickedSessionId: session.id.toString(),
        clickedPageId: params.clickedItemId,
        isShiftKey: params.isShiftKey,
        isCtrlOrCmdKey: params.isCtrlOrCmdKey,
        isCurrentlySelected: params.isCurrentlySelected,
      })
    },
    [handleCardPageSelection, session],
  )

  const handleRemovePageSelection = useCallback(
    (params: { pageId: string }) => {
      handleRemoveCardPageSelection({
        pageId: params.pageId,
        sessionId: session.id.toString(),
      })
    },
    [handleRemoveCardPageSelection, session],
  )

  const [{ isDraggingThis }, connectDragSource, connectDragPreview] = useDrag(
    () => ({
      type: DND_ITEM_ENUM.SMART_SESSION,
      collect: (monitor) => ({
        isDraggingThis: monitor.isDragging(),
      }),
      end: () => dispatch(setIsSmartSessionDragging(false)),
      item: () => {
        dispatch(setIsSmartSessionDragging(true))
        const payload: DnDItemSmartSessionPayload = {
          type: DND_ITEM_ENUM.SMART_SESSION,
          session: session,
        }
        return payload
      },
    }),
    [dispatch, session],
  )

  useEffect(() => {
    connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [connectDragPreview])

  return (
    <Container
      ref={isSSOnboardingActive ? undefined : connectDragSource}
      $isDraggingThis={isDraggingThis}
      $isHoverDisabled={isSSOnboardingActive}
    >
      <SmartSessionCardTitle
        title={session.name}
        handleClickOpenIcon={handleClickOpenIcon}
        handleClickOpenInNewWindowIcon={handleClickOpenInNewWindowIcon}
        handleClickCreateProjectIcon={handleClickCreateProject}
        handleClickDeleteIcon={handleDeleteSmartSession}
        isSSOnboardingActive={isSSOnboardingActive}
        isFolderCreationModeActive={isFolderCreationModeActive}
        shouldHideActionIcons={shouldHideCardActionIcons}
        handleClickFolderCreationAddAllButton={handleClickFolderCreationAddAllButton}
      />
      <Box>
        {pages.map((page, idx) => {
          return (
            <PutasideTabController
              key={page.id}
              id={page.id}
              page={page}
              queryValue=""
              index={idx}
              numTotalResults={session.pages.length}
              areaName={TABLIST_AREA_NAME_ENUM.SmartSessions}
              showTimeString={true}
              showActionIcons={true}
              showOpenTabIcon={page.is_open}
              isDraggingDisabled={isSSOnboardingActive}
              isTitleClickDisabled={isSSOnboardingActive}
              isHoverDisabled={isSSOnboardingActive}
              isSelectionDisabled={isSSOnboardingActive}
              onDeleteSmartSessionPage={
                isSSOnboardingActive ? undefined : handleDeleteSmartSessionPage
              }
              shouldShowDeleteIcon={true}
              shouldShowFolderCreationAddIcon={isFolderCreationModeActive}
              isPendingInFolderCreation={pendingFolderCreationPages.some(
                (p) => p.instance_id === page.instance_id,
              )}
              onSelected={handlePageSelection}
              removeSelection={handleRemovePageSelection}
              isSelected={`${session.id.toString()}-${page.id}` in selectedPages}
              isTabAboveSelected={
                idx > 0 && `${session.id.toString()}-${pages[idx - 1].id}` in selectedPages
              }
              isTabBelowSelected={
                idx < pages.length - 1 &&
                `${session.id.toString()}-${pages[idx + 1].id}` in selectedPages
              }
            />
          )
        })}
      </Box>
    </Container>
  )
}

export default SmartSessionCard
