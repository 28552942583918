import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Flex, Text, Button, Spinner, Box, ToastId, Divider } from '@chakra-ui/react'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import { TablistPageTopicGroupType, TablistPageType } from '../../../models/tablist_pages.types'
import {
  useDeleteTablistPageMutation,
  useGetAllSavedTabsQuery,
  useLazyGetAllSavedTabsQuery,
} from '../../../redux/services/skeema/tablist_pages.endpoints'
import {
  loadOlderSavedPages,
  selectHighlightedPageIds,
  setHighlightedPageIds,
  updateMostRecentSavedPages,
} from '../../../redux/tablistSlice'
import { useUserContext } from '../../../contexts/UserContext'
import { useLocation, useNavigate } from 'react-router-dom'
import SavedPagesLimitModal from './SavedPagesLimitModal'
import { MoveItemPageInfoType, MoveTablistPageInfoType } from '../moveItemMenu/moveItemMenu.types'
import MoveItemModal from '../moveItemMenu/MoveItemModal'
import { HIGHLIGHT_ANIMATION_DURATION_MS } from './PutasideTabView'
import {
  handleSavedForLaterPageSelection,
  removeSelectedSavedForLaterPages,
  selectSelectedSavedForLaterPages,
} from '../../../redux/selectedPagesSlice'
import MultiSelectToolbar from '../multiSelectToolbar/MultiSelectToolbar'
import { CHROME_STORAGE_LOCAL_KEYS } from '../../../extension/models/extensionStorage.types'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import {
  selectIsFolderCreationModeActive,
  selectPendingFolderCreationPages,
} from '../../../redux/projectsSlice'
import SavedTabsCard from '../savedTabs/SavedTabsCard'
import ProjectLimitModal from '../projectsSidebar/ProjectLimitModal'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import {
  selectNumActiveProjects,
  useCreateProjectMutation,
  useGetActiveProjectsQuery,
} from '../../../redux/services/skeema/projects.endpoints'
import { useLocalStorageBoolean } from '../../../utils/genericHooks'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../../models/browserStorage.types'

function groupPagesByDate(pages: TablistPageType[]) {
  const now = new Date()
  const todayStartOffset = 3
  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), todayStartOffset)
  const startOfYesterday = new Date(
    startOfToday.getTime() - (24 + todayStartOffset) * 60 * 60 * 1000,
  )
  const startOfTwoDaysAgo = new Date(startOfToday.getTime() - 2 * 24 * 60 * 60 * 1000)
  const startOfSevenDaysAgo = new Date(startOfToday.getTime() - 7 * 24 * 60 * 60 * 1000)
  const startOfEightDaysAgo = new Date(startOfToday.getTime() - 8 * 24 * 60 * 60 * 1000)
  const startOfMonthOfEightDaysAgo = new Date(
    startOfEightDaysAgo.getFullYear(),
    startOfEightDaysAgo.getMonth(),
    1,
  )

  const grouped = pages.reduce(
    (acc, p) => {
      const lastAccessDate = new Date(p.last_access_timestamp_ms)
      let groupHeader: string

      if (lastAccessDate >= startOfToday) {
        groupHeader = 'Today'
      } else if (lastAccessDate >= startOfYesterday) {
        groupHeader = 'Yesterday'
      } else if (lastAccessDate >= startOfTwoDaysAgo) {
        groupHeader = lastAccessDate.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
        })
      } else if (lastAccessDate >= startOfSevenDaysAgo) {
        groupHeader = 'Last 7 days'
      } else if (lastAccessDate >= startOfMonthOfEightDaysAgo) {
        groupHeader = `Earlier in ${lastAccessDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
        })}`
      } else {
        groupHeader = lastAccessDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
        })
      }

      if (!acc[groupHeader]) {
        acc[groupHeader] = {
          header: groupHeader,
          pages: [],
          order: p.last_access_timestamp_ms,
        }
      }
      acc[groupHeader].pages.push(p)
      return acc
    },
    {} as Record<string, { header: string; pages: TablistPageType[]; order: number }>,
  )

  const sortedGroups = Object.values(grouped).sort((a, b) => {
    return b.order - a.order
  })

  return sortedGroups
}

const SavedForLaterList: FC = () => {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const { captureAnalytics } = useUserContext()

  const toastIdRef = useRef<ToastId | undefined>(undefined)

  const shouldRefetchOnMount = useRef<boolean>(true)
  const [isProjectLimitModalOpen, setIsProjectLimitModalOpen] = useState<boolean>(false)
  const { projectConfig } = useFeatureFlagContext()
  const numProjects = useReduxSelector(selectNumActiveProjects)
  const maxNumProjects = projectConfig.maxNumProjects
  const isProjectLimitReached = numProjects === undefined || numProjects >= maxNumProjects

  const [isSavedPagesLimitModalOpen, setIsSavedPagesLimitModalOpen] = useState<boolean>(false)
  const [groups, setGroups] = useState<Record<string, TablistPageTopicGroupType> | undefined>(
    undefined,
  )

  const [shouldASTIncludeUserClosedTabs] = useLocalStorageBoolean(
    WEBAPP_LOCAL_STORAGE_KEYS.ShouldASTIncludeUserClosedTabs,
    true,
  )

  const isFolderCreationModeActive = useReduxSelector(selectIsFolderCreationModeActive)
  const pendingFolderCreationPages = useReduxSelector(selectPendingFolderCreationPages)

  const selectedPages = useReduxSelector(selectSelectedSavedForLaterPages)
  const isSelectionActive = Object.keys(selectedPages).length > 0

  const highlightedPageIds = useReduxSelector(selectHighlightedPageIds)
  useEffect(() => {
    if (highlightedPageIds && highlightedPageIds.length > 0) {
      setTimeout(() => {
        dispatch(setHighlightedPageIds(undefined))
      }, HIGHLIGHT_ANIMATION_DURATION_MS)
    }
  }, [dispatch, highlightedPageIds])

  const { hash } = useLocation()
  const shouldRetrieveHighlightedPages = hash === '#highlighted_tabs'
  useEffect(() => {
    if (shouldRetrieveHighlightedPages) {
      void sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_CHROME_STORAGE, {
        storageType: 'local',
      })
        .then((result) => {
          if (result) {
            const highlightedPageIds = (result as { [key: string]: string[] })[
              CHROME_STORAGE_LOCAL_KEYS.HighlightedPageIds
            ]
            if (highlightedPageIds && highlightedPageIds.length > 0) {
              dispatch(setHighlightedPageIds(highlightedPageIds))
            }
          }
        })
        .catch((e) => {
          console.error(e)
          dispatch(setHighlightedPageIds(undefined))
        })
        .finally(() => {
          navigate(window.location.pathname)
        })
    }
  }, [shouldRetrieveHighlightedPages, dispatch, navigate])

  const savedPages = useReduxSelector((state) => state.tablist.savedPages)
  const groupedPages = useMemo(() => {
    if (!savedPages) {
      return savedPages
    }
    return groupPagesByDate(savedPages)
  }, [savedPages])

  const [_deleteTablistPage] = useDeleteTablistPageMutation()
  const deleteTablistPage = _deleteTablistPage as unknown as (params: {
    page: TablistPageType
    index: number
  }) => Promise<void>

  const { data: tablistPagesQueryResult, refetch: refetchTablistPagesQuery } =
    useGetAllSavedTabsQuery({
      page: 1,
      should_include_most_visited: true,
      should_include_user_closed: !!shouldASTIncludeUserClosedTabs,
    })
  const mostRecentSavedPages = tablistPagesQueryResult?.tablist

  useEffect(() => {
    //Syncing the most recent saved pages with the redux store
    mostRecentSavedPages && dispatch(updateMostRecentSavedPages(mostRecentSavedPages))
  }, [dispatch, mostRecentSavedPages])

  useEffect(() => {
    if (shouldRefetchOnMount.current) {
      // TODO: Fix. This is a hack for RTKQ optimistic updates not being setup properly
      // and updates not being reflected during magic moments or after updating and switching to & from a project
      refetchTablistPagesQuery()
      shouldRefetchOnMount.current = false
    }
  }, [refetchTablistPagesQuery])

  useEffect(() => {
    if (tablistPagesQueryResult) {
      setGroups(tablistPagesQueryResult.groups)
    }
  }, [tablistPagesQueryResult])

  const [
    triggerGetOlderTablistPagesQuery,
    {
      data: olderTablistPagesQueryData,
      error: olderTablistPagesQueryError,
      isFetching: isMoreSavedPagesLoading,
    },
  ] = useLazyGetAllSavedTabsQuery({ refetchOnFocus: false })

  const doMoreSavedPagesExist = olderTablistPagesQueryData
    ? olderTablistPagesQueryData.next_page !== null
    : tablistPagesQueryResult?.next_page !== null

  useEffect(() => {
    //Loading the older "load more" saved pages into the redux store
    if (olderTablistPagesQueryError) {
      console.error(olderTablistPagesQueryError)
      return
    }

    if (olderTablistPagesQueryData) {
      const newGroups = olderTablistPagesQueryData.groups
      setGroups((prev) => {
        return { ...prev, ...newGroups }
      })
      dispatch(loadOlderSavedPages(olderTablistPagesQueryData.tablist))
    }
  }, [dispatch, olderTablistPagesQueryData, olderTablistPagesQueryError])

  const nextPage = olderTablistPagesQueryData?.next_page ?? tablistPagesQueryResult?.next_page
  const onClickShowMoreSavedPages = useCallback(() => {
    captureAnalytics('saved_for_later_list:show_more_saved_pages_click')

    if (!nextPage) {
      return
    }

    triggerGetOlderTablistPagesQuery({
      page: nextPage,
      should_include_most_visited: false,
      should_include_user_closed: !!shouldASTIncludeUserClosedTabs,
    })
  }, [captureAnalytics, nextPage, shouldASTIncludeUserClosedTabs, triggerGetOlderTablistPagesQuery])

  const [moveSavedItemPageInfo, setMoveSavedItemPageInfo] = useState<
    MoveItemPageInfoType | undefined
  >(undefined)
  const handleOpenMoveSavedItemModal = useCallback(
    (params: { pageInfo: MoveTablistPageInfoType }) => {
      const { pageInfo } = params
      setMoveSavedItemPageInfo(pageInfo)
      captureAnalytics('saved_for_later_list:move_saved_item_modal_open', {
        ...(pageInfo ?? {}),
      })
    },
    [captureAnalytics, setMoveSavedItemPageInfo],
  )

  const { data: existingProjects } = useGetActiveProjectsQuery(undefined)
  const [createProjectMutation] = useCreateProjectMutation()

  const handleCreateProject = useCallback(
    async (title: string, tablistPages: TablistPageType[]) => {
      if (isProjectLimitReached) {
        return
      }
      await createProjectMutation({
        title,
        tablistPages,
        existingProjects: existingProjects === undefined ? [] : existingProjects,
        titlePrefix: 'Folder',
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })
    },
    [createProjectMutation, existingProjects, isProjectLimitReached],
  )

  const handlePageSelection = useCallback(
    (params: {
      clickedItemId: string
      isShiftKey: boolean
      isCtrlOrCmdKey: boolean
      isCurrentlySelected: boolean
      extraAnalyticsProps?: Record<string, unknown>
    }): void => {
      const { clickedItemId, isShiftKey, isCtrlOrCmdKey, extraAnalyticsProps } = params

      if (!savedPages) {
        return
      }

      dispatch(
        handleSavedForLaterPageSelection({
          pages: savedPages,
          clickedItemId,
          isShiftKey,
          isCtrlOrCmdKey,
        }),
      )

      captureAnalytics('saved_for_later_list:page_select', {
        clickedItemId,
        numSelectedPages: Object.keys(selectedPages).length,
        isCurrentlySelected: clickedItemId in selectedPages,
        isShiftKey,
        isCtrlOrCmdKey,
        ...(extraAnalyticsProps ?? {}),
      })
    },
    [savedPages, dispatch, captureAnalytics, selectedPages],
  )

  const handleRemovePageSelection = useCallback(
    (params: { pageId: string }) => {
      const { pageId } = params
      dispatch(removeSelectedSavedForLaterPages([pageId]))

      captureAnalytics('saved_for_later_list:page_selection_remove_side_effect', {
        pageId,
        numSelectedPages: Object.keys(selectedPages).length,
        isCurrentlySelected: pageId in selectedPages,
      })
    },
    [dispatch, captureAnalytics, selectedPages],
  )

  const handleDismissSavedPagesLimitModal = () => {
    captureAnalytics('saved_for_later_list:saved_pages_limit_modal_dismiss_click')
    setIsSavedPagesLimitModalOpen(false)
  }

  const handleSubmitSavedPagesLimitModal = () => {
    captureAnalytics('saved_for_later_list:saved_pages_limit_modal_upgrade_click')
    setIsSavedPagesLimitModalOpen(false)
    navigate('/#pricing')
  }

  const handleProjectLimitError = useCallback(() => {
    captureAnalytics('saved_for_later_list:project_limit_modal_show')
    setIsProjectLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissProjectLimitModal = () => {
    captureAnalytics('saved_for_later_list:project_limit_modal_dismiss_click')
    setIsProjectLimitModalOpen(false)
  }

  const handleSubmitProjectLimitModal = () => {
    captureAnalytics('saved_for_later_list:project_limit_modal_upgrade_click')
    setIsProjectLimitModalOpen(false)
    navigate('/#pricing')
  }

  return (
    <Box>
      <Box m={isSelectionActive ? '4px 0 16px 0px' : undefined}>
        <MultiSelectToolbar />
      </Box>

      {!savedPages && (
        <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
          <Spinner color="blue.500" size="lg" speed="1s" />
        </div>
      )}

      {savedPages && savedPages.length === 0 && (
        <div className="flex-center" style={{ width: '100%' }}>
          <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
            Check back later! Your saved tabs will be right here.
          </Text>
        </div>
      )}

      <Flex flexDirection="column" mt={isSelectionActive ? '12px' : undefined}>
        {groupedPages &&
          groupedPages.map((g, idx) => {
            return (
              <React.Fragment key={g.header}>
                <Text mt="4px" ml="12px" mb="8px" fontSize="12px" fontWeight={500} color="#A7A7A7">
                  {`${g.header} - ${g.pages.length} ${g.pages.length === 1 ? 'tab' : 'tabs'} saved`}
                </Text>
                <Flex flexDirection="column">
                  <SavedTabsCard
                    pages={g.pages}
                    pendingFolderCreationPages={pendingFolderCreationPages}
                    isFolderCreationModeActive={isFolderCreationModeActive}
                    highlightedPageIds={highlightedPageIds ?? []}
                    selectedPages={selectedPages}
                    deleteTablistPage={deleteTablistPage}
                    handleOpenMoveSavedItemModal={handleOpenMoveSavedItemModal}
                    groups={groups}
                    handleCreateProject={handleCreateProject}
                    handlePageSelection={handlePageSelection}
                    handleRemovePageSelection={handleRemovePageSelection}
                    handleProjectLimitError={handleProjectLimitError}
                    isProjectLimitReached={false}
                    shouldHideCardActionIcons={false}
                    toastIdRef={toastIdRef}
                  />
                </Flex>
                {idx !== groupedPages.length - 1 && (
                  <Divider my="18px" ml="12px" width="calc(100% - 24px)" borderColor="#d5d5d5" />
                )}
              </React.Fragment>
            )
          })}

        {/* START LOAD MORE BUTTON FOR TABLIST */}
        {doMoreSavedPagesExist !== undefined && (
          <Flex w="100%" alignItems="center" justifyContent="flex-end">
            {doMoreSavedPagesExist && (
              <Button
                key="extra"
                size="sm"
                fontSize={12}
                fontWeight={500}
                borderRadius={'16px'}
                mt={'8px'}
                h="32px"
                minH="32px"
                w="100%"
                px={2}
                onClick={onClickShowMoreSavedPages}
                bg="#F6F6F6"
                color="#585858"
                _hover={{ bg: '#EBEBEB' }}
                isLoading={isMoreSavedPagesLoading}
              >
                {`Show older`}
              </Button>
            )}
            {!doMoreSavedPagesExist && (savedPages?.length ?? 0) !== 0 && (
              <Text
                fontSize={12}
                fontWeight={500}
                mt="8px"
                w="100%"
                px={2}
                color="#A7A7A7"
                textAlign="center"
              >
                {`You've reached the end of your saved tabs history`}
              </Text>
            )}
          </Flex>
        )}
      </Flex>

      {/* END LOAD MORE BUTTON FOR TABLIST */}

      <SavedPagesLimitModal
        isOpen={isSavedPagesLimitModalOpen}
        onCancel={handleDismissSavedPagesLimitModal}
        onSubmit={handleSubmitSavedPagesLimitModal}
      />

      <ProjectLimitModal
        isOpen={isProjectLimitModalOpen}
        onCancel={handleDismissProjectLimitModal}
        onSubmit={handleSubmitProjectLimitModal}
      />

      <MoveItemModal pageInfo={moveSavedItemPageInfo} setPageInfo={setMoveSavedItemPageInfo} />
    </Box>
  )
}

export default SavedForLaterList
