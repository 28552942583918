import React, { FC, useEffect, useState } from 'react'
import { Box, Flex, Heading, Icon } from '@chakra-ui/react'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { styled } from 'styled-components'
import { cx, css } from '@emotion/css'
import { MdOutlineViewAgenda } from 'react-icons/md'
import SmartSessionDashboard from '../SmartSessionDashboard'

export interface SelectedTablistPageType {
  [id: string]: TablistPageType
}

export enum TABLIST_AREA_NAME_ENUM {
  MostUsed = 'most_used_tabs',
  Open = 'open_tabs',
  RecentlySaved = 'other_recently_used_tabs',
  SearchResults = 'search_results',
  SmartSessions = 'smart_sessions',
  FeedHistory = 'feed_history',
  FolderCreation = 'folder_creation',
}

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const onboardingPrepAnimationClassName = css`
  transition: opacity 2000ms ease-in-out 500ms;
  opacity: 0;
`

const onboardingAnimationClassName = css`
  opacity: 1;
`

export enum PUTASIDE_TABLIST_VIEW_ENUM {
  SAVED_TABS = 'SAVED_TABS',
  SESSIONS = 'SESSIONS',
}

const SmartSessionTablist: FC = () => {
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(true)
    }, 500)
  }, [])

  return (
    <Box
      className={cx({
        [onboardingPrepAnimationClassName]: true,
        [onboardingAnimationClassName]: shouldAnimate,
        scrollbars: true,
      })}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      overflowY={'auto'}
      borderRadius={'16px 16px 0px 0px'}
      backgroundColor={'#f6f6f6'}
    >
      <Box p="24px 16px">
        <SectionContainer>
          <Box mb="12px">
            <Flex m="0px 0px" alignItems="center" position="relative" zIndex={2}>
              <Box cursor="pointer" p="10px 10px 0 10px" pointerEvents={'auto'} opacity={1}>
                <Flex
                  p="0 2px 6px 2px"
                  alignItems="center"
                  borderBottom="2px solid"
                  borderBottomColor={'#000'}
                  color={'#000'}
                  transition="all 0.2s"
                  gap="6px"
                >
                  <Icon as={MdOutlineViewAgenda} width="24px" height="24px" />
                  <Heading
                    as="h2"
                    color="inherit"
                    fontSize="16px"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    Sessions
                  </Heading>
                </Flex>
              </Box>
            </Flex>

            <Box
              borderBottom="1px solid #D5D5D5"
              margin="0px 8px 0px 10px"
              position="relative"
              top="-1px"
              zIndex={1}
            />
          </Box>

          <SmartSessionDashboard isSSOnboardingActive={true} />
        </SectionContainer>
      </Box>
    </Box>
  )
}

export default SmartSessionTablist
