import React, { FC, useEffect, useState } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import {
  GLOBAL_WINDOW_MESSAGE_ENUM,
  onGlobalWindowMessage,
  sendGlobalWindowMessage,
} from '../../../utils/utils'

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingPinTabsDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const [dialogState, setDialogState] = useState<'INFO' | 'SUCCESS'>('INFO')
  const [numPinnedTabs, setNumPinnedTabs] = useState<number>(0)
  useEffect(() => {
    if (dialogState !== 'SUCCESS') {
      return
    }

    const timeout = setTimeout(() => {
      onNext()
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [dialogState, onNext])

  useEffect(() => {
    return onGlobalWindowMessage(
      GLOBAL_WINDOW_MESSAGE_ENUM.PIN_TABS_SUCCESS,
      ({ success, numTabs }: { success: boolean; numTabs: number }) => {
        if (success) {
          setNumPinnedTabs(numTabs)
          setDialogState('SUCCESS')
        } else {
          onNext()
        }
      },
    )
  }, [onNext])

  const onButtonClick = () => {
    sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.SUBMIT_PIN_TABS)
  }

  if (dialogState === 'SUCCESS') {
    return (
      <OnboardingStepTemplate
        phaseText={`1. DECLUTTERING`}
        phaseNum={phaseNum}
        numPhases={numPhases}
        heading={`Done! ${
          numPinnedTabs > 0
            ? `${
                numPinnedTabs === 1 ? 'This tab' : `These ${numPinnedTabs} tabs`
              } will stay open for you.`
            : ''
        }`}
        height="300px"
      />
    )
  }

  return (
    <OnboardingStepTemplate
      phaseText={`1. DECLUTTERING`}
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={
        <>
          {`First, let's pin the few tabs you `}
          <u
            style={{
              fontSize: 'inherit',
              fontWeight: 'inherit',
              lineHeight: '40px',
              color: 'inherit',
              letterSpacing: '-1px',
            }}
          >
            always
          </u>{' '}
          want open so that Skipper knows not to put them away.
        </>
      }
      description={`Skipper won't close pinned tabs. Daily use apps like email and calendar are good candidates to pin now.`}
      buttonText={`I've pinned what I want`}
      onButtonClick={onButtonClick}
    />
  )
}
export default OnboardingPinTabsDialog
