import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import {
  Input,
  Button,
  Box,
  Wrap,
  WrapItem,
  Text,
  Icon,
  Flex,
  Tooltip,
  Spinner,
  Heading,
  HStack,
} from '@chakra-ui/react'
import {
  useCreateProjectMutation,
  useGetActiveProjectsQuery,
  useLazyGetProjectRecommendationsQuery,
  useMoveProjectMutation,
} from '../../../redux/services/skeema/projects.endpoints'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { HiSparkles } from 'react-icons/hi'
import { useReduxDispatch, useReduxSelector } from '../../../redux/baseStore'
import {
  addPendingFolderCreationPages,
  cancelFolderCreationMode,
  selectPendingFolderCreationPages,
  selectPendingFolderCreationTitle,
  selectSuggestedFolderCreationPages,
  selectSuggestedFolderCreationTitles,
  setPendingFolderCreationTitle,
  setSuggestedFolderCreationPages,
  setSuggestedFolderCreationTitles,
} from '../../../redux/projectsSlice'
import { MdHelpOutline } from 'react-icons/md'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useUserContext } from '../../../contexts/UserContext'
import { ProjectType } from '../../../models/saved_sessions.types'
import { DnDItemPayload } from '../../../models/dnd.types'
import { useDrop } from 'react-dnd'
import { DND_ITEM_ENUM } from '../../../models/dnd.types'
import { SIDEBAR_TRANSITION_DURATION_MS } from '../MainDashboard'
import { PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM } from '../PutasideTabList/PutasideTabView'
import { clearSelectedPages } from '../../../redux/selectedPagesSlice'
import { GLOBAL_WINDOW_MESSAGE_ENUM, sendGlobalWindowMessage } from '../../../utils/utils'
import CurvedArrowAltSVG from '../../../utils/svgs/CurvedArrowAltSVG'
import { retryFunction } from '../../../utils/utils'

const ProjectCreationOnboardingWidget: FC = () => {
  const dispatch = useReduxDispatch()
  const { captureAnalytics } = useUserContext()
  const pendingTitle = useReduxSelector(selectPendingFolderCreationTitle)
  const suggestedTitles = useReduxSelector(selectSuggestedFolderCreationTitles)
  const suggestedPages = useReduxSelector(selectSuggestedFolderCreationPages)

  const [folderName, setFolderName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingRecommendationsWithRetries, setIsLoadingRecommendationsWithRetries] =
    useState<boolean>(false)

  const dropTargetContainerRef = useRef<HTMLDivElement | null>(null)
  const pendingPages = useReduxSelector(selectPendingFolderCreationPages)
  const numPendingPages = pendingPages.length

  const numSteps = 3
  const currentStep = !pendingTitle ? 1 : pendingPages.length === 0 ? 2 : 3

  const [isSidebarFullyExpanded, setIsSidebarFullyExpanded] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setIsSidebarFullyExpanded(true)
    }, SIDEBAR_TRANSITION_DURATION_MS + 500)
  }, [])

  const [
    getProjectRecommendations,
    {
      data: recommendationsQueryData,
      isFetching: isFetchingRecommendationsQuery,
      isError: isErrorRecommendationsQuery,
    },
  ] = useLazyGetProjectRecommendationsQuery({
    refetchOnFocus: false,
  })

  const isLoadingRecommendations =
    isFetchingRecommendationsQuery || isLoadingRecommendationsWithRetries

  const [didServerRequestsFail, setDidServerRequestsFail] = useState<boolean>(false)
  const [didServerReturnEmptyRecommendations, setDidServerReturnEmptyRecommendations] =
    useState<boolean>(false)

  useEffect(() => {
    const fetchProjectRecommendations = async () => {
      setIsLoadingRecommendationsWithRetries(true)
      const cb = () =>
        getProjectRecommendations(
          { title: folderName, pages: pendingPages, isOnboarding: true },
          true,
        ).unwrap()
      const { success, result } = await retryFunction(cb, 3, 1000, 2)
      if (!success) {
        Sentry.captureException(Error(`Failed to get project recommendations from server`))
        setDidServerRequestsFail(true)
      }

      if (result && folderName && result.pages.length === 0) {
        setDidServerReturnEmptyRecommendations(true)
      }

      setIsLoadingRecommendationsWithRetries(false)
    }

    fetchProjectRecommendations()
  }, [folderName, pendingPages, getProjectRecommendations])

  useEffect(() => {
    if (pendingPages.length >= 2 || isLoading) {
      //Prevent this triggering when the "Add all" step is clicked
      return
    }

    if (didServerRequestsFail) {
      captureAnalytics(`project_creation_onboarding_widget:server_requests_failed`, {
        recommendationsQueryData,
        isErrorRecommendationsQuery,
      })

      dispatch(cancelFolderCreationMode())

      sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.SKIP_FOLDER_ONBOARDING_STEPS)
    } else if (didServerReturnEmptyRecommendations) {
      captureAnalytics(`project_creation_onboarding_widget:server_returned_empty_recommendations`, {
        recommendationsQueryData,
        isErrorRecommendationsQuery,
      })

      dispatch(cancelFolderCreationMode())

      sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.SKIP_FOLDER_ONBOARDING_STEPS)
    }
  }, [
    captureAnalytics,
    didServerRequestsFail,
    didServerReturnEmptyRecommendations,
    dispatch,
    recommendationsQueryData,
    isErrorRecommendationsQuery,
    isLoading,
    pendingPages.length,
  ])

  useEffect(() => {
    if (!recommendationsQueryData) {
      return
    }

    dispatch(setSuggestedFolderCreationTitles(recommendationsQueryData.titles ?? []))
    dispatch(setSuggestedFolderCreationPages(recommendationsQueryData.pages))
  }, [dispatch, recommendationsQueryData])

  const handleClickTitleSuggestion = useCallback(
    (title: string) => {
      dispatch(setPendingFolderCreationTitle(title))
      setFolderName(title)

      captureAnalytics(`project_creation_onboarding_widget:title_suggestion_click`, {
        title,
        suggestedTitles,
      })
    },
    [captureAnalytics, dispatch, suggestedTitles],
  )

  const [createProjectMutation] = useCreateProjectMutation()
  const [moveProjectMutation] = useMoveProjectMutation()

  const { data: projects } = useGetActiveProjectsQuery(undefined)
  const handleCreateProject = useCallback(
    async (params: {
      tablistPages: TablistPageType[]
      title?: string
      order?: number
    }): Promise<ProjectType | undefined> => {
      const { tablistPages, title, order } = params

      const result = await createProjectMutation({
        tablistPages,
        title,
        existingProjects: projects,
        order,
        titlePrefix: 'Folder',
        isOnboarding: true,
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      if (result) {
        await moveProjectMutation({ id: result.id, order: 0 })
      }

      return result
    },
    [createProjectMutation, projects, moveProjectMutation],
  )

  const handleSave = useCallback(async () => {
    const project = await handleCreateProject({
      tablistPages: pendingPages,
      title: pendingTitle,
    })

    if (project) {
      captureAnalytics(`project_creation_onboarding_widget:save_exec`, {
        numPages: pendingPages.length,
        title: pendingTitle,
        pages: pendingPages,
        projectId: project?.id,
      })

      sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.TRIGGER_NEXT_ONBOARDING_STEP)

      dispatch(cancelFolderCreationMode())
    } else {
      captureAnalytics(`project_creation_onboarding_widget:failed_save_exec`, {
        numPages: pendingPages.length,
        title: pendingTitle,
        pages: pendingPages,
      })

      console.error('Failed to create project')
      sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.TRIGGER_NEXT_ONBOARDING_STEP)
      dispatch(cancelFolderCreationMode())
    }
  }, [handleCreateProject, pendingPages, pendingTitle, captureAnalytics, dispatch])

  const handleClickAddAllSuggestedTabs = useCallback(async () => {
    if (!suggestedPages) {
      return
    }
    setIsLoading(true)

    dispatch(addPendingFolderCreationPages({ pages: suggestedPages, index: 0 }))

    captureAnalytics(`project_creation_onboarding_widget:add_all_suggested_tabs_click`, {
      numPages: suggestedPages.length,
    })

    await handleSave()

    setIsLoading(false)
  }, [captureAnalytics, dispatch, suggestedPages, handleSave])

  const { projectConfig } = useFeatureFlagContext()
  const maxNumPages = projectConfig.maxNumProjectsPages
  const isProjectPageLimitReached = numPendingPages >= maxNumPages
  const isTablistPageDragging = useReduxSelector((state) => state.dnd.isTablistPageDragging)
  const [dragOverLocation, setDragOverLocation] = useState<'top' | 'bottom' | undefined>(undefined)
  const dragOverLocationRef = useRef<'top' | 'bottom' | undefined>(undefined)

  useEffect(() => {
    dragOverLocationRef.current = dragOverLocation
  }, [dragOverLocation])

  const [{ isDraggingOverThis, shouldShowPageLimitError }, connectDropTarget] = useDrop(
    () => ({
      accept: [
        DND_ITEM_ENUM.TABLIST_PAGE,
        DND_ITEM_ENUM.TABLIST_PAGES,
        DND_ITEM_ENUM.SMART_SESSION,
      ],
      collect: (monitor) => {
        const pl: DnDItemPayload | null = monitor.getItem()
        const payload =
          pl?.type === DND_ITEM_ENUM.TABLIST_PAGES && pl?.payloads?.length === 1
            ? pl.payloads[0]
            : pl

        const isDraggingOverThis = monitor.isOver()

        const isDragItemFromThisProject =
          payload?.type === DND_ITEM_ENUM.TABLIST_PAGE &&
          payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION
        const numAddPages = isDragItemFromThisProject
          ? 0
          : payload?.type === DND_ITEM_ENUM.SMART_SESSION
            ? payload?.session.pages.length
            : payload?.type === DND_ITEM_ENUM.TABLIST_PAGES
              ? payload?.payloads.length
              : 1
        const newNumPages = numPendingPages + numAddPages

        const shouldShowPageLimitError = isDraggingOverThis && newNumPages > maxNumPages

        return {
          isDraggingOverThis,
          shouldShowPageLimitError,
        }
      },
      canDrop: (pl: DnDItemPayload) => {
        const payload =
          pl.type === DND_ITEM_ENUM.TABLIST_PAGES && pl.payloads.length === 1 ? pl.payloads[0] : pl

        if (payload.type === DND_ITEM_ENUM.TABLIST_PAGE) {
          if (isProjectPageLimitReached) {
            return false
          }

          const isFromThisProject =
            payload?.type === DND_ITEM_ENUM.TABLIST_PAGE &&
            payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION

          if (isFromThisProject) {
            const isTopPointless = payload.index === 0 && dragOverLocationRef.current === 'top'
            const isBottomPointless =
              payload.index === numPendingPages - 1 && dragOverLocationRef.current === 'bottom'
            if (isTopPointless || isBottomPointless) {
              return false
            }
          }
        } else if (
          payload.type === DND_ITEM_ENUM.SMART_SESSION ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const newNumPages =
            numPendingPages +
            (payload.type === DND_ITEM_ENUM.SMART_SESSION
              ? payload.session.pages.length
              : payload.payloads.length)
          if (newNumPages > maxNumPages) {
            return false
          }
        }

        return true
      },
      hover: (pl, monitor) => {
        const payload =
          pl.type === DND_ITEM_ENUM.TABLIST_PAGES && pl.payloads.length === 1 ? pl.payloads[0] : pl

        const { y } = monitor.getClientOffset() ?? {}
        const { top, bottom, height } =
          dropTargetContainerRef.current?.getBoundingClientRect() ?? {}
        if (top === undefined || bottom === undefined || y === undefined || height === undefined) {
          setDragOverLocation(undefined)
          return
        }

        if (monitor.isOver({ shallow: true })) {
          //Dropping a page on the project container edges/title
          const newDragLocation = y < top + 60 ? 'top' : y > bottom - 18 ? 'bottom' : undefined

          const isFromThisProject =
            payload?.type === DND_ITEM_ENUM.TABLIST_PAGE &&
            payload.page.entity_type === TABLIST_PAGE_ENUM.FOLDER_CREATION
          if (isFromThisProject) {
            const isTopPointless = payload.index === 0 && newDragLocation === 'top'
            const isBottomPointless =
              payload.index === numPendingPages - 1 && newDragLocation === 'bottom'
            if (isTopPointless || isBottomPointless) {
              //Trying to drop in the same location
              setDragOverLocation(undefined)
              return
            }
          }

          setDragOverLocation(newDragLocation)
        } else {
          setDragOverLocation(undefined)
        }
      },
      drop: (pl: DnDItemPayload, monitor) => {
        const payload =
          pl.type === DND_ITEM_ENUM.TABLIST_PAGES && pl.payloads.length === 1 ? pl.payloads[0] : pl

        if (monitor.didDrop()) {
          //Another drop target (a child item) received the drop event already
          return { status: 'DID_DROP' }
        }

        if (
          payload.type === DND_ITEM_ENUM.TABLIST_PAGE ||
          payload.type === DND_ITEM_ENUM.TABLIST_PAGES
        ) {
          const index = dragOverLocationRef.current === 'bottom' ? numPendingPages : 0

          const payloads =
            payload.type === DND_ITEM_ENUM.TABLIST_PAGES ? payload.payloads : [payload]
          const tablistPages = payloads.map((pl) => pl.page)
          dispatch(addPendingFolderCreationPages({ pages: tablistPages, index }))
          dispatch(clearSelectedPages())

          return { status: 'SUCCESS' }
        } else if (payload.type === DND_ITEM_ENUM.SMART_SESSION) {
          const index = dragOverLocationRef.current === 'bottom' ? numPendingPages : 0
          const tablistPages = [...payload.session.pages]
          if (pendingTitle === '') {
            dispatch(setPendingFolderCreationTitle(payload.session.name))
          }
          dispatch(addPendingFolderCreationPages({ pages: tablistPages, index }))
          return { status: 'SUCCESS' }
        }

        return { status: 'ERROR' }
      },
    }),
    [dispatch, pendingTitle, numPendingPages, maxNumPages, isProjectPageLimitReached],
  )

  const connectDnd = useCallback(
    (node: HTMLDivElement | null) => {
      dropTargetContainerRef.current = node
      connectDropTarget(dropTargetContainerRef)
    },
    [connectDropTarget],
  )

  const widgetCard = (
    <Flex
      direction="column"
      w="100%"
      overflow="hidden"
      p="20px"
      borderRadius="10px"
      bg="#FFF"
      border="1.5px solid #D5D5D5"
    >
      <Text ml="6px" mb="10px" color="#A7A7A7" fontSize="14px" fontWeight="600" lineHeight="22px">
        Name
      </Text>

      <Input
        mb={4}
        h="36px"
        p="4px 8px"
        borderRadius="6px"
        border="1px solid #D5D5D5"
        background="#FFF"
        placeholder="Select folder name"
        value={pendingTitle}
        readOnly={true}
        color="#585858"
        fontSize="16px"
        fontWeight="600"
        lineHeight="24px"
        pointerEvents="none"
        tabIndex={-1}
        _placeholder={{
          color: '#A7A7A7',
        }}
        _focus={{
          borderRadius: '6px',
          border: '1px solid #0071E3',
          background: '#FFF',
          boxShadow: '0px 0px 0px 4px rgba(0, 113, 227, 0.25)',
        }}
      />

      {!pendingTitle && pendingPages.length === 0 && !isErrorRecommendationsQuery && (
        <Box mb={currentStep > 1 ? '24px' : '0px'}>
          <Flex ml="6px" mb="10px" w="100%" alignItems="center" position="relative">
            <Icon as={HiSparkles} mr="6px" color="#0071e3" />
            <Text color="#0071e3" fontSize="14px" fontWeight="600" lineHeight="22px">
              Recommended folders for you
            </Text>
            <Tooltip
              label={`Based on your recent browsing, Skipper AI recommends setting up the folders below`}
              placement="top"
            >
              <Flex ml="8px" alignItems="center" justifyContent="center">
                <Icon as={MdHelpOutline} color="#0071e3" />
              </Flex>
            </Tooltip>
          </Flex>

          <Wrap
            spacing="8px"
            maxHeight={isSidebarFullyExpanded ? '300px' : '0px'}
            opacity={isSidebarFullyExpanded ? 1 : 0}
            transition="all 0.6s ease-in-out"
            overflow="hidden"
          >
            {!isLoadingRecommendations && isSidebarFullyExpanded && suggestedTitles?.[0] && (
              <>
                <WrapItem key={suggestedTitles?.[0]} position="absolute" zIndex={2}>
                  <Button
                    size="sm"
                    h="28px"
                    p="0 12px"
                    border="1px solid #0071E3"
                    background="#CCE3F9"
                    color="#0071E3"
                    borderRadius="8px"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="16px"
                    animation="outline-blink 2s infinite"
                    sx={{
                      '@keyframes outline-blink': {
                        '0%': {
                          boxShadow: '0 0 0 0px rgba(0, 113, 227, 0.6)',
                        },
                        '50%': {
                          boxShadow: '0 0 0 6px rgba(0, 113, 227, 0.6)',
                        },
                        '100%': {
                          boxShadow: '0 0 0 0px rgba(0, 113, 227, 0.6)',
                        },
                      },
                    }}
                    onClick={() => handleClickTitleSuggestion(suggestedTitles?.[0])}
                  >
                    {suggestedTitles?.[0]}
                  </Button>
                </WrapItem>
                {suggestedTitles?.slice(0, 3).map((title) => (
                  <WrapItem key={title} pointerEvents={'none'} position="relative" zIndex={1}>
                    <Button
                      size="sm"
                      h="28px"
                      p="0 12px"
                      border="1px solid #0071E3"
                      background="#CCE3F9"
                      color="#0071E3"
                      borderRadius="8px"
                      fontSize="12px"
                      fontWeight="500"
                      lineHeight="16px"
                    >
                      {title}
                    </Button>
                  </WrapItem>
                ))}
              </>
            )}
          </Wrap>

          {(isLoadingRecommendations || !isSidebarFullyExpanded || !suggestedTitles) && (
            <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
              <Spinner color="blue.500" size="md" speed="1s" />
            </Flex>
          )}

          {!isLoadingRecommendations && suggestedTitles && suggestedTitles.length === 0 && (
            <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
              <Text textAlign={'center'} fontSize="12px" color="#a7a7a7">
                {`No recommendations found`}
              </Text>
            </Flex>
          )}
        </Box>
      )}
      {currentStep > 1 && (
        <>
          <Box ref={connectDnd}>
            <Flex mb="10px" justifyContent="space-between" alignItems="flex-end">
              <Text ml="6px" color="#A7A7A7" fontSize="14px" fontWeight="600" lineHeight="22px">
                Tabs
              </Text>
              {pendingPages.length > 0 && (
                <Text fontSize="12px" fontWeight="500" color="#a7a7a7" mr="87px">
                  {`Last used`}
                </Text>
              )}
            </Flex>

            {pendingPages.length === 0 && (
              <Box
                border={isTablistPageDragging ? '2px dashed #0071E3' : '2px dashed #D5D5D5'}
                bg={
                  isTablistPageDragging
                    ? isDraggingOverThis
                      ? 'rgba(0, 113, 227, 0.40)'
                      : 'rgba(0, 113, 227, 0.20)'
                    : '#F6F6F6'
                }
                color={isTablistPageDragging ? '#0071E3' : '#A7A7A7'}
                borderRadius="6px"
                p={4}
                mb={4}
                textAlign="center"
                fontSize="14px"
                fontWeight="500"
                animation={
                  currentStep === 2 &&
                  !isLoadingRecommendations &&
                  suggestedPages &&
                  suggestedPages.length > 0
                    ? 'blink-dnd-colors 3s infinite'
                    : undefined
                }
                sx={{
                  '@keyframes blink-dnd-colors': {
                    '0%': {
                      background: '#F6F6F6',
                      borderColor: '#D5D5D5',
                      color: '#A7A7A7',
                    },
                    '25%': {
                      background: 'rgba(0, 113, 227, 0.40)',
                      borderColor: '#0071E3',
                      color: '#0071E3',
                    },
                    '75%': {
                      background: 'rgba(0, 113, 227, 0.40)',
                      borderColor: '#0071E3',
                      color: '#0071E3',
                    },
                    '100%': {
                      background: '#F6F6F6',
                      borderColor: '#D5D5D5',
                      color: '#A7A7A7',
                    },
                  },
                }}
              >
                {`Drag and drop tabs here`}
              </Box>
            )}

            {pendingPages.length > 0 && (
              <Box
                position="relative"
                mb={4}
                border={isTablistPageDragging ? '2px dashed #0071E3' : '2px dashed transparent'}
                bg={
                  shouldShowPageLimitError
                    ? 'rgba(240, 63, 54, 0.20)'
                    : isTablistPageDragging
                      ? isDraggingOverThis
                        ? 'rgba(0, 113, 227, 0.40)'
                        : 'rgba(0, 113, 227, 0.20)'
                      : 'transparent'
                }
                transition="all 0.2s ease-in-out"
                borderRadius="6px"
                pointerEvents="none"
              >
                {pendingPages.map((tab, index) => (
                  <PutasideTabController
                    key={tab.id}
                    areaName={TABLIST_AREA_NAME_ENUM.FolderCreation}
                    id={tab.id}
                    page={tab}
                    queryValue={''}
                    index={index}
                    numTotalResults={pendingPages.length}
                    styleVariant={PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.FOLDER_CREATION}
                    isHighlighted={false}
                    isSelected={false}
                    isTabAboveSelected={false}
                    isTabBelowSelected={false}
                    shouldShowDeleteIcon={true}
                    isPendingInFolderCreation={true}
                    shouldForceHighlightTop={
                      index === 0 && isDraggingOverThis && dragOverLocation === 'top'
                    }
                    shouldForceHighlightBottom={
                      index === pendingPages.length - 1 &&
                      isDraggingOverThis &&
                      dragOverLocation === 'bottom'
                    }
                    isProjectPageLimitReached={isProjectPageLimitReached}
                    numProjectPages={pendingPages.length}
                    maxNumProjectPages={maxNumPages}
                    isTitleClickDisabled={true}
                  />
                ))}
              </Box>
            )}
          </Box>

          {(!!pendingTitle ||
            pendingPages.length > 0 ||
            (suggestedPages && suggestedPages.length > 0)) &&
            !isErrorRecommendationsQuery && (
              <Box mb="24px">
                <Flex
                  ml="6px"
                  mb="10px"
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex alignItems="center">
                    <Icon as={HiSparkles} mr="6px" color="#0071e3" />
                    <Text color="#0071e3" fontSize="14px" fontWeight="600" lineHeight="22px">
                      Recommended tabs
                    </Text>
                    <Tooltip
                      label={`Based on the folder you're creating, Skipper AI recommends including the tabs below`}
                      placement="top"
                    >
                      <Flex ml="8px" alignItems="center" justifyContent="center">
                        <Icon as={MdHelpOutline} color="#0071e3" />
                      </Flex>
                    </Tooltip>
                  </Flex>
                  {suggestedPages && suggestedPages.length > 1 && (
                    <Tooltip placement="top" label={'Add all tabs to folder'}>
                      <Flex
                        bg="#CCE3F9"
                        borderRadius="6px"
                        color="#0071E3"
                        w="67px"
                        h="22px"
                        flexShrink={0}
                        alignItems="center"
                        justifyContent="center"
                        onClick={handleClickAddAllSuggestedTabs}
                        cursor="pointer"
                        mr="10px"
                        pointerEvents={currentStep === 3 ? 'auto' : 'none'}
                        animation={currentStep === 3 ? 'outline-blink 2s infinite' : 'none'}
                        sx={{
                          '@keyframes outline-blink': {
                            '0%': {
                              boxShadow: '0 0 0 0px rgba(0, 113, 227, 0.6)',
                            },
                            '50%': {
                              boxShadow: '0 0 0 6px rgba(0, 113, 227, 0.6)',
                            },
                            '100%': {
                              boxShadow: '0 0 0 0px rgba(0, 113, 227, 0.6)',
                            },
                          },
                        }}
                      >
                        <Text fontSize="12px" fontWeight="500">
                          + Add all
                        </Text>
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>

                {!isLoadingRecommendations && (
                  <Box
                    border="2px dashed transparent"
                    // The border keeps these tabs aligned with the tabs in the dnd zone above
                    position="relative"
                  >
                    {currentStep === 2 &&
                      suggestedPages?.slice(0, 1).map((tab, index) => (
                        <Box
                          key={tab.id}
                          w="100%"
                          position="absolute"
                          zIndex={2}
                          bg="white"
                          borderRadius="6px"
                          boxShadow="0 0 0 4px rgba(0, 113, 227, 0.9)"
                          animation="outline-blink 3s infinite"
                          sx={{
                            '& *': {
                              cursor: 'grabbing !important',
                            },
                            '& .DragIcon': {
                              animation: 'opacity-blink 3s infinite',
                              transition: 'none !important',
                            },
                            '& > div > svg': {
                              animation: 'scale-blink 3s infinite',
                              transformOrigin: 'bottom center',
                            },
                            '@keyframes opacity-blink': {
                              '0%': {
                                opacity: '0',
                              },
                              '25%': {
                                opacity: '1',
                              },
                              '75%': {
                                opacity: '1',
                              },
                              '100%': {
                                opacity: '0',
                              },
                            },
                            '@keyframes outline-blink': {
                              '0%': {
                                boxShadow: '0 0 0 0px rgba(0, 113, 227, 0.6)',
                              },
                              '25%': {
                                boxShadow: '0 0 0 6px rgba(0, 113, 227, 0.9)',
                              },
                              '75%': {
                                boxShadow: '0 0 0 6px rgba(0, 113, 227, 0.9)',
                              },
                              '100%': {
                                boxShadow: '0 0 0 0px rgba(0, 113, 227, 0.6)',
                              },
                            },
                            '@keyframes scale-blink': {
                              '0%': {
                                opacity: '0.3',
                                transform: 'scale(0.8)',
                              },
                              '25%': {
                                opacity: '1',
                                transform: 'scale(1.1)',
                              },
                              '75%': {
                                opacity: '1',
                                transform: 'scale(1.1)',
                              },
                              '100%': {
                                opacity: '0.3',
                                transform: 'scale(0.8)',
                              },
                            },
                          }}
                        >
                          <Box
                            position="absolute"
                            bottom="calc(100% + 6px)"
                            left="50%"
                            pointerEvents="none"
                          >
                            <CurvedArrowAltSVG />
                          </Box>

                          <PutasideTabController
                            key={tab.id}
                            id={tab.id}
                            page={tab}
                            queryValue={''}
                            index={index}
                            numTotalResults={suggestedPages.length}
                            areaName={TABLIST_AREA_NAME_ENUM.FolderCreation}
                            styleVariant={PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.FOLDER_CREATION}
                            onOpenMoveMenu={() => {}}
                            isHighlighted={false}
                            isSelected={false}
                            isTabAboveSelected={false}
                            isTabBelowSelected={false}
                            onSelected={() => {}}
                            removeSelection={() => {}}
                            shouldShowFolderCreationAddIcon={false}
                            isTitleClickDisabled={true}
                            showTimeString={false}
                            showActionIcons={false}
                          />
                        </Box>
                      ))}
                    {suggestedPages?.map((tab, index) => (
                      <Box key={tab.id} pointerEvents="none" position="relative" zIndex={1}>
                        <PutasideTabController
                          key={tab.id}
                          id={tab.id}
                          page={tab}
                          queryValue={''}
                          index={index}
                          numTotalResults={suggestedPages.length}
                          areaName={TABLIST_AREA_NAME_ENUM.FolderCreation}
                          styleVariant={PUTASIDE_TAB_VIEW_STYLE_VARIANT_ENUM.FOLDER_CREATION}
                          onOpenMoveMenu={() => {}}
                          isHighlighted={false}
                          isSelected={false}
                          isTabAboveSelected={false}
                          isTabBelowSelected={false}
                          onSelected={() => {}}
                          removeSelection={() => {}}
                          shouldShowFolderCreationAddIcon={true}
                          isTitleClickDisabled={true}
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {(isLoadingRecommendations || !suggestedPages) && (
                  <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
                    <Spinner color="blue.500" size="md" speed="1s" />
                  </Flex>
                )}

                {!isLoadingRecommendations && suggestedPages && suggestedPages.length === 0 && (
                  <Flex justifyContent="center" alignItems="center" w="100%" m="8px">
                    <Text textAlign={'center'} fontSize="12px" color="#a7a7a7">
                      {`No recommendations found`}
                    </Text>
                  </Flex>
                )}
              </Box>
            )}
        </>
      )}
    </Flex>
  )

  return (
    <Flex
      direction="column"
      w="100%"
      h="100%"
      overflow="hidden"
      p="32px"
      borderRadius="16px"
      bg="#FFF"
      boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.12)"
    >
      <Flex justifyContent="space-between" mb="12px" flexWrap="nowrap">
        <Text
          color="#A7A7A7"
          fontSize="16px"
          fontWeight={500}
          lineHeight="22px"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          mr="8px"
        >
          {`2. ORGANIZING`}
        </Text>
        <HStack>
          <Box
            width="200px"
            height="12px"
            borderRadius={'24px'}
            borderWidth="1px"
            borderStyle="solid"
            borderColor={currentStep === 1 ? '#CCE3F9' : '#0071E3'}
            color="white"
            whiteSpace="nowrap"
            flexShrink={0}
          >
            <Box
              h="100%"
              w={`${60 + ((currentStep === 1 ? 0 : currentStep) / numSteps) * 20}%`}
              borderRadius={currentStep === numSteps ? '24px' : '24px 0 0 24px'}
              bg="#0071E3"
            />
          </Box>
        </HStack>
      </Flex>

      {isLoadingRecommendations && currentStep === 1 && (
        <Flex alignItems="center" justifyContent="center" w="100%" h="100%" p="32px">
          <Spinner color="blue.500" size="xl" speed="1s" />
        </Flex>
      )}

      {(!isLoadingRecommendations || currentStep > 1) && (
        <Box className="scrollbars-always-visible" overflow="auto">
          <Heading
            mb="16px"
            fontSize={'24px'}
            fontWeight={500}
            lineHeight={'40px'}
            color="#000"
            letterSpacing={'-1px'}
          >
            {currentStep === 1
              ? `First, select ${
                  suggestedTitles?.[0] ? `"${suggestedTitles[0]}",` : ''
                } Skipper's AI recommendation.`
              : currentStep === 2
                ? 'Great! Drag and drop the first tab.'
                : 'Awesome! Now add the related tabs too.'}
          </Heading>
          {!isLoading && widgetCard}
          {isLoading && (
            <Flex alignItems="center" justifyContent="center" w="100%" h="100%" p="32px">
              <Spinner color="blue.500" size="xl" speed="1s" />
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  )
}

export default ProjectCreationOnboardingWidget
