import React, { FC } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingFolderCreatedDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      phaseText={`2. ORGANIZING`}
      numPhases={numPhases}
      heading={
        <span
          style={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            lineHeight: 'inherit',
            color: 'inherit',
            letterSpacing: 'inherit',
          }}
        >
          Congrats, you just created your first folder! 🎉
        </span>
      }
      buttonText={`Yay!`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingFolderCreatedDialog
